import React, { Component } from 'react';
import { Select as AntdSelect, Form } from 'antd';
import SearchableSelect from './SearchableSelect';
import Label from './Label';
import { errorProps } from '../../../../utils/errors';

class Select extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  // Handle change for select input
  handleChange(value) {
    const { onChange, name } = this.props;

    let newValue = value;
    if (newValue === undefined) {
      newValue = null;
    }

    onChange(name, newValue);
  }

  render() {
    const {
      children,
      name,
      value,
      label,
      placeholder,
      errors,
      onChange,
      required,
      tooltip,
      remote,
      parentStyle,
      ...otherProps
    } = this.props;

    const SelectComponent = remote ? SearchableSelect : AntdSelect;

    return (
      <Form.Item
        label={label ? <Label text={label} tooltip={tooltip} /> : undefined}
        required={required}
        {...errorProps(errors, name)}
        style={parentStyle}
      >
        <SelectComponent
          name={name}
          // Note: Placeholder will only appear if value is undefined
          value={value === null ? undefined : value}
          placeholder={placeholder || label}
          onChange={this.handleChange}
          {...(remote ? ({ showSearch: true, filterOption: false }) : undefined)}
          {...otherProps}
        >
          {children}
        </SelectComponent>
      </Form.Item>
    );
  }
}

Select.Option = AntdSelect.Option;

export default Select;
