import { defineMessages } from 'react-intl';
import beach from '../../images/beach.jpg';
import boating from '../../images/boating.jpg';
import fishing from '../../images/fishing.jpg';
import golf from '../../images/golf.jpg';
import hiking from '../../images/hiking.jpg';
import swimming from '../../images/swimming.jpg';

export const TRANSACTION_METHODS = {
  CREDIT_CARD: 'creditCard',
  DEBIT: 'debit',
  CASH: 'cash',
  CHEQUE: 'cheque',
  TERMINAL_MASTERCARD: 'terminalMasterCard',
  TERMINAL_VISA: 'terminalVisa',
  TERMINAL_AMEX: 'terminalAmex',
  TERMINAL_DISCOVER: 'terminalDiscover',
  ETRANSFER: 'eTransfer',
  STRIPE_TERMINAL_CREDIT: 'stripeTerminal',
  STRIPE_TERMINAL_DEBIT: 'stripeTerminalDebit',
  JOURNAL_ENTRY: 'journalEntry',
};

export const TRANSACTION_METHOD_MESSAGES = defineMessages({
  terminal: { defaultMessage: 'Terminal' },
  [TRANSACTION_METHODS.CREDIT_CARD]: { defaultMessage: 'Credit Card' },
  [TRANSACTION_METHODS.DEBIT]: { defaultMessage: 'Debit' },
  [TRANSACTION_METHODS.CASH]: { defaultMessage: 'Cash' },
  [TRANSACTION_METHODS.CHEQUE]: { defaultMessage: 'Cheque' },
  [TRANSACTION_METHODS.ETRANSFER]: { defaultMessage: 'e-Transfer' },
  [TRANSACTION_METHODS.TERMINAL_AMEX]: { defaultMessage: 'Terminal American Express' },
  [TRANSACTION_METHODS.TERMINAL_DISCOVER]: { defaultMessage: 'Terminal Discover' },
  [TRANSACTION_METHODS.TERMINAL_MASTERCARD]: { defaultMessage: 'Terminal MasterCard' },
  [TRANSACTION_METHODS.TERMINAL_VISA]: { defaultMessage: 'Terminal Visa' },
  [TRANSACTION_METHODS.STRIPE_TERMINAL_CREDIT]: { defaultMessage: 'Stripe Terminal (Credit)' },
  [TRANSACTION_METHODS.STRIPE_TERMINAL_DEBIT]: { defaultMessage: 'Stripe Terminal (Debit)' },
  [TRANSACTION_METHODS.JOURNAL_ENTRY]: { defaultMessage: 'Journal Entry' },
});

export const TERMINAL_PAYMENT_METHOD = {
  value: 'terminal',
  translation: TRANSACTION_METHOD_MESSAGES.terminal,
};

export const CREDIT_PAYMENT_METHOD = {
  value: TRANSACTION_METHODS.CREDIT_CARD,
  translation: TRANSACTION_METHOD_MESSAGES[TRANSACTION_METHODS.CREDIT_CARD],
};

export const CASH_PAYMENT_METHOD = {
  value: TRANSACTION_METHODS.CASH,
  translation: TRANSACTION_METHOD_MESSAGES[TRANSACTION_METHODS.CASH],
};

export const CHEQUE_PAYMENT_METHOD = {
  value: TRANSACTION_METHODS.CHEQUE,
  translation: TRANSACTION_METHOD_MESSAGES[TRANSACTION_METHODS.CHEQUE],
};

export const E_TRANSFER_PAYMENT_METHOD = {
  value: TRANSACTION_METHODS.ETRANSFER,
  translation: TRANSACTION_METHOD_MESSAGES[TRANSACTION_METHODS.ETRANSFER],
};

export const STRIPE_TERMINAL_CREDIT_PAYMENT_METHOD = {
  value: TRANSACTION_METHODS.STRIPE_TERMINAL_CREDIT,
  translation: TRANSACTION_METHOD_MESSAGES[TRANSACTION_METHODS.STRIPE_TERMINAL_CREDIT],
};

export const STRIPE_TERMINAL_DEBIT_PAYMENT_METHOD = {
  value: TRANSACTION_METHODS.STRIPE_TERMINAL_DEBIT,
  translation: TRANSACTION_METHOD_MESSAGES[TRANSACTION_METHODS.STRIPE_TERMINAL_DEBIT],
};

export const TRANSACTION_METHOD_OPTIONS = [
  TERMINAL_PAYMENT_METHOD,
  CASH_PAYMENT_METHOD,
  CHEQUE_PAYMENT_METHOD,
  E_TRANSFER_PAYMENT_METHOD,
];

export const TRANSACTION_METHOD_OPTIONS_WITH_CREDIT = [
  TERMINAL_PAYMENT_METHOD,
  CREDIT_PAYMENT_METHOD,
  CASH_PAYMENT_METHOD,
  CHEQUE_PAYMENT_METHOD,
  E_TRANSFER_PAYMENT_METHOD,
];

export const TERMINAL_AMEX_PAYMENT_METHOD = {
  value: 'terminalAmex',
  translation: TRANSACTION_METHOD_MESSAGES.terminalAmex,
};

export const TERMINAL_DISCOVER_PAYMENT_METHOD = {
  value: 'terminalDiscover',
  translation: TRANSACTION_METHOD_MESSAGES.terminalDiscover,
};

export const TERMINAL_MASTERCARD_PAYMENT_METHOD = {
  value: 'terminalMasterCard',
  translation: TRANSACTION_METHOD_MESSAGES.terminalMasterCard,
};

export const TERMINAL_VISA_PAYMENT_METHOD = {
  value: 'terminalVisa',
  translation: TRANSACTION_METHOD_MESSAGES.terminalVisa,
};

export const DEBIT_PAYMENT_METHOD = {
  value: 'debit',
  translation: TRANSACTION_METHOD_MESSAGES.debit,
};

export const ALL_TRANSACTION_METHODS = [
  CREDIT_PAYMENT_METHOD,
  CASH_PAYMENT_METHOD,
  CHEQUE_PAYMENT_METHOD,
  DEBIT_PAYMENT_METHOD,
  E_TRANSFER_PAYMENT_METHOD,
  TERMINAL_AMEX_PAYMENT_METHOD,
  TERMINAL_DISCOVER_PAYMENT_METHOD,
  TERMINAL_MASTERCARD_PAYMENT_METHOD,
  TERMINAL_VISA_PAYMENT_METHOD,
  STRIPE_TERMINAL_CREDIT_PAYMENT_METHOD,
  STRIPE_TERMINAL_DEBIT_PAYMENT_METHOD,
];

export const TERMINAL_TRANSACTION_METHODS = [
  DEBIT_PAYMENT_METHOD,
  {
    value: 'terminalMasterCard',
    label: 'MasterCard',
  },
  {
    value: 'terminalVisa',
    label: 'Visa',
  },
  {
    value: 'terminalAmex',
    label: 'American Express',
  },
  {
    value: 'terminalDiscover',
    label: 'Discover',
  },
];

export const ACTIVITIES_MESSAGES = defineMessages({
  swimming: { defaultMessage: 'Swimming' },
  boating: { defaultMessage: 'Boating' },
  fishing: { defaultMessage: 'Fishing' },
  beach: { defaultMessage: 'The Beach' },
  golfing: { defaultMessage: 'Golfing' },
  hiking: { defaultMessage: 'Hiking' },
});

export const ACTIVITIES = [
  {
    value: 'swimming',
    src: swimming,
    translation: ACTIVITIES_MESSAGES.swimming,
    query: {
      amenities: ['swimming'],
    },
  },
  {
    value: 'boating',
    src: boating,
    translation: ACTIVITIES_MESSAGES.boating,
    query: {
      amenities: ['boatLaunch'],
    },
  },
  {
    value: 'fishing',
    src: fishing,
    translation: ACTIVITIES_MESSAGES.fishing,
    query: {
      amenities: ['fishing'],
    },
  },
  {
    value: 'beach',
    src: beach,
    translation: ACTIVITIES_MESSAGES.beach,
    query: {
      amenities: ['beach'],
    },
  },
  {
    value: 'golfing',
    src: golf,
    translation: ACTIVITIES_MESSAGES.golfing,
    query: {
      amenities: ['golf'],
    },
  },
  {
    value: 'hiking',
    src: hiking,
    translation: ACTIVITIES_MESSAGES.hiking,
    query: {
      amenities: ['hikingTrail'],
    },
  },
];

export const QUESTION_TYPE_MESSAGES = defineMessages({
  text: { defaultMessage: 'Text' },
  number: { defaultMessage: 'Number of' },
  bool: { defaultMessage: 'Yes/No' },
});

export const QUESTION_TYPE_TEXT = 'text';
export const QUESTION_TYPE_NUMBER = 'number';
export const QUESTION_TYPE_YESNO = 'bool';

export const QUESTION_TYPES = [
  {
    value: QUESTION_TYPE_TEXT,
    translation: {
      ...QUESTION_TYPE_MESSAGES.text,
    },
  },
  {
    value: QUESTION_TYPE_NUMBER,
    translation: {
      ...QUESTION_TYPE_MESSAGES.number,
    },
  },
  {
    value: QUESTION_TYPE_YESNO,
    translation: {
      ...QUESTION_TYPE_MESSAGES.bool,
    },
  },
];

export const UNIT_TYPE_MESSAGES = defineMessages({
  other: { defaultMessage: 'Other' },
  lodging: { defaultMessage: 'Lodging' },
  tent: { defaultMessage: 'Tent' },
  rv: { defaultMessage: 'RV' },
});

export const UNIT_TYPE_TENT = 'tent';
export const UNIT_TYPE_RV = 'rv';
export const UNIT_TYPE_LODGING = 'lodging';
export const UNIT_TYPE_OTHER = 'other';
export const UNIT_TYPE_BOAT = 'boat';

export const UNIT_TYPES = [
  {
    value: UNIT_TYPE_TENT,
    translation: {
      ...UNIT_TYPE_MESSAGES.tent,
    },
  },
  {
    value: UNIT_TYPE_RV,
    translation: {
      ...UNIT_TYPE_MESSAGES.rv,
    },
  },
  {
    value: UNIT_TYPE_LODGING,
    translation: {
      ...UNIT_TYPE_MESSAGES.lodging,
    },
  },
  {
    value: UNIT_TYPE_OTHER,
    translation: {
      ...UNIT_TYPE_MESSAGES.other,
    },
  },
];

export const BOAT_UNIT_TYPE_MESSAGES = defineMessages({
  boat: { defaultMessage: 'Boat' },
});

export const BOAT_UNIT_TYPES = [
  {
    value: UNIT_TYPE_BOAT,
    translation: {
      ...BOAT_UNIT_TYPE_MESSAGES.boat,
    },
  },
];

export const ALL_UNIT_TYPES = [...UNIT_TYPES, ...BOAT_UNIT_TYPES];

export const ALL_UNIT_TYPE_MESSAGES = { ...UNIT_TYPE_MESSAGES, ...BOAT_UNIT_TYPE_MESSAGES };

export const SINGLE_UNIT_TYPES = ['lodging', 'other'];

export const DEFAULT_UNIT_TYPES = [
  UNIT_TYPE_TENT, UNIT_TYPE_RV, UNIT_TYPE_LODGING,
];

export const NON_SINGLE_UNIT_TYPES = ALL_UNIT_TYPES.filter(
  (type) => !SINGLE_UNIT_TYPES.includes(type.value),
);

export const LINE_ITEM_CATEGORIES = ['accommodation', 'addOn', 'fee', 'bookingFee', 'bookingFeeCredit', 'tax', 'cancellationFee', 'nonRefundable', 'refund', 'promo', 'changeFee', 'dynamicPricing'];
export const LINE_ITEM_CATEGORIES_WHICH_ALLOW_DELETION = ['fee', 'tax', 'cancellationFee', 'changeFee', 'nonRefundable'];
export const LINE_ITEM_CATEGORIES_WHICH_ALLOW_REDUCTION = ['accommodation', 'addOn', 'fee', 'cancellationFee', 'changeFee', 'bookingFee', 'nonRefundable', 'dynamicPricing'];
export const ONE_TIME_ADJUSTMENT_LINE_ITEM_CATEGORIES = ['nonRefundable'];

export const [
  LINE_ITEM_ACCOMMODATION, LINE_ITEM_ADD_ON, LINE_ITEM_FEE, LINE_ITEM_BOOKING_FEE,
  LINE_ITEM_BOOKING_FEE_CREDIT, LINE_ITEM_TAX, LINE_ITEM_CANCELLATION_FEE,
  LINE_ITEM_NON_REFUNDABLE_AMOUNT, LINE_ITEM_CREDIT, LINE_ITEM_PROMO_CODE, LINE_ITEM_CHANGE_FEE,
  LINE_ITEM_DYNAMIC_PRICING,
] = LINE_ITEM_CATEGORIES;

export const BOOKING_STATUS_MESSAGES = defineMessages({
  cancelled: { defaultMessage: 'Cancelled' },
  completed: { defaultMessage: 'Completed' },
  ongoing: { defaultMessage: 'Ongoing' },
  pending: { defaultMessage: 'Pending' },
  upcoming: { defaultMessage: 'Upcoming' },
});

export const BOOKING_STATUSES = [
  {
    value: 'upcoming',
    translation: BOOKING_STATUS_MESSAGES.upcoming,
  },
  {
    value: 'ongoing',
    translation: BOOKING_STATUS_MESSAGES.ongoing,
  },
  {
    value: 'completed',
    translation: BOOKING_STATUS_MESSAGES.completed,
  },
  {
    value: 'cancelled',
    translation: BOOKING_STATUS_MESSAGES.cancelled,
  },
  {
    value: 'pending',
    translation: BOOKING_STATUS_MESSAGES.pending,
  },
];

export const BOOKING_STATUS_CANCELLED = BOOKING_STATUSES[3].value;

export const BOOKING_STATUS_COMPLETED = BOOKING_STATUSES[2].value;

export const BOOKING_STATUS_ONGOING = BOOKING_STATUSES[1].value;

export const BOOKING_STATUS_UPCOMING = BOOKING_STATUSES[0].value;

export const AGE_RANGE_MESSAGES = defineMessages({
  adult: { defaultMessage: 'Adults' },
  infant: { defaultMessage: 'Infants' },
  senior: { defaultMessage: 'Seniors' },
  youth: { defaultMessage: 'Youths' },
});

export const AGE_RANGE_DESCRIPTION_MESSAGES = defineMessages({
  adult: { defaultMessage: 'Ages 18-64' },
  infant: { defaultMessage: 'Under 2' },
  senior: { defaultMessage: 'Ages 65 or above' },
  youth: { defaultMessage: 'Ages 2-17' },
});

export const LINE_ITEM_CATEGORY_MESSAGES = defineMessages({
  tax: { defaultMessage: 'Tax' },
  bookingFee: { defaultMessage: 'Booking Fee' },
  bookingFeeCredit: { defaultMessage: 'Booking Fee Credit' },
  accommodation: { defaultMessage: 'Accommodation' },
  refund: { defaultMessage: 'Refund' },
  addOn: { defaultMessage: 'Add On' },
  fee: { defaultMessage: 'Fee' },
  cancellationFee: { defaultMessage: 'Cancellation Fee' },
  nonRefundable: { defaultMessage: 'Non-Refundable' },
  promo: { defaultMessage: 'Promo Code' },
  changeFee: { defaultMessage: 'Change Fee' },
  dynamicPricing: { defaultMessage: 'Dynamic Pricing' },
});

export const CANCELLATION_REASON_MESSAGES = defineMessages({
  weather: { defaultMessage: 'Weather' },
  change_plans: { defaultMessage: 'Change of Plans' },
  issue: { defaultMessage: 'Issue with Site/Campground' },
  other: { defaultMessage: 'Other' },
});
