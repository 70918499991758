import React, { Component } from 'react';
import { Input as AntdInput, Form } from 'antd';
import { errorProps } from '../../../../utils/errors';
import style from './Input.module.less';
import Label from './Label';

class Input extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  // Handle change for text input
  handleChange(event) {
    const { name, value } = event.target;
    const { onChange, type } = this.props;

    let newValue = value;
    if (type === 'number') {
      newValue = parseFloat(newValue);
    }

    onChange(name, newValue);
  }

  render() {
    const {
      name,
      textArea,
      value,
      placeholder,
      errors,
      onChange,
      required,
      tooltip,
      label,
      additionalText,
      parentStyle,
      ...otherProps
    } = this.props;

    const InputComponent = textArea ? AntdInput.TextArea : AntdInput;

    return (
      <Form.Item
        label={label ? <Label text={label} tooltip={tooltip} /> : undefined}
        required={required}
        {...errorProps(errors, name)}
        style={parentStyle}
      >
        {
          additionalText ? (
            <div className={style.additionalText}>{additionalText}</div>
          ) : null
        }
        <InputComponent
          name={name}
                // Note: Placeholder will only appear if value is undefined
          value={value != null ? value : undefined}
          placeholder={placeholder || label}
          onChange={this.handleChange}
          {...otherProps}
        />
      </Form.Item>
    );
  }
}

export default Input;
