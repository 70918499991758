import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import Portal from '../../common/components/Portal';
import Nav from './Nav';

const UserIndexContainer = lazy(() => import('./user/UserIndexContainer'));
const UserViewContainer = lazy(() => import('./user/UserViewContainer'));
const UserCreate = lazy(() => import('./user/UserCreate'));
const UserEditContainer = lazy(() => import('./user/UserEditContainer'));
const UserChangePasswordContainer = lazy(() => import('./user/UserChangePasswordContainer'));

const CampIndexContainer = lazy(() => import('./camp/CampIndexContainer'));
const CampViewContainer = lazy(() => import('./camp/CampViewContainer'));
const CampCreateContainer = lazy(() => import('./camp/CampCreateContainer'));
const CampEditContainer = lazy(() => import('./camp/CampEditContainer'));
const CampRatesEditContainer = lazy(() => import('./camp/CampRatesEditContainer'));

const TierIndexContainer = lazy(() => import('./tier/TierIndexContainer'));
const TierViewContainer = lazy(() => import('./tier/TierViewContainer'));
const TierCreate = lazy(() => import('./tier/TierCreate'));
const TierEditContainer = lazy(() => import('./tier/TierEditContainer'));

const ClientIndexContainer = lazy(() => import('./client/ClientIndexContainer'));
const ClientViewContainer = lazy(() => import('./client/ClientViewContainer'));
const ClientCreate = lazy(() => import('./client/ClientCreate'));
const ClientEditContainer = lazy(() => import('./client/ClientEditContainer'));

const BookingFeeReportTypeContainer = lazy(() => import('./report/BookingFeeReportTypeContainer'));
const BookingLookupContainer = lazy(() => import('./support/BookingLookupContainer'));

const StripeTerminalCreate = lazy(() => import('./camp/StripeTerminalCreate'));

const NotFoundError = lazy(() => import('../../common/components/NotFoundError'));

function Routes() {
  return (
    <Portal Nav={Nav}>
      <Switch>
        <Route exact path="/admin" component={CampIndexContainer} />

        <Route exact path="/admin/users" component={UserIndexContainer} />
        <Route exact path="/admin/users/new" component={UserCreate} />
        <Route exact path="/admin/users/:id" component={UserViewContainer} />
        <Route exact path="/admin/users/:id/edit" component={UserEditContainer} />
        <Route exact path="/admin/users/:id/change-password" component={UserChangePasswordContainer} />

        <Route exact path="/admin/camps" component={CampIndexContainer} />
        <Route exact path="/admin/camps/new" component={CampCreateContainer} />
        <Route exact path="/admin/camps/:id/edit" component={CampEditContainer} />
        <Route exact path="/admin/camps/:id/rates/edit" component={CampRatesEditContainer} />
        <Route exact path="/admin/camps/:id" component={CampViewContainer} />
        <Route exact path="/admin/camps/:id/stripe-terminals/register" component={StripeTerminalCreate} />

        <Route exact path="/admin/tiers" component={TierIndexContainer} />
        <Route exact path="/admin/tiers/new" component={TierCreate} />
        <Route exact path="/admin/tiers/:id/edit" component={TierEditContainer} />
        <Route exact path="/admin/tiers/:id" component={TierViewContainer} />

        <Route exact path="/admin/clients" component={ClientIndexContainer} />
        <Route exact path="/admin/clients/new" component={ClientCreate} />
        <Route exact path="/admin/clients/:id" component={ClientViewContainer} />
        <Route exact path="/admin/clients/:id/edit" component={ClientEditContainer} />

        <Route exact path="/admin/booking-fee-report" component={BookingFeeReportTypeContainer} />

        <Route exact path="/admin/booking-lookup" component={BookingLookupContainer} />

        <Route component={NotFoundError} />
      </Switch>
    </Portal>
  );
}

export default Routes;
